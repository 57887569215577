.media-pane-container {
  // min-width: 460px;
}

.file-picker-card {
  width: max-content;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
