.photo-viewer-grid {
  display: grid;
  grid-template-columns: 48px 1fr 48px;
  grid-template-rows: calc(100vh - 12rem) 48px;
  grid-template-areas:
    "button1 main button2"
    "footer footer footer";
  justify-items: auto;
  align-items: center;
  gap: 20px;
}

.photo-viewer-grid-button1 {
  grid-area: button1;
}

.photo-viewer-grid-button2 {
  grid-area: button2;
}

.photo-viewer-grid-main {
  grid-area: main;
  justify-self: center;
  align-content: center;
}

.photo-viewer-grid-footer {
  grid-area: footer;
  justify-self: center;
}
